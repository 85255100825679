import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Desc,
  DescP,
  DescSpan,
  H3,
} from "./pending.styles";
import { getCharges } from "../../Redux/apiCalls";
import { Wrapper } from "./transHistory.styles";
import { formatAmount } from "../../utills/formatNumber";
import { PageNotification, PaginateContainer, PagWrapper } from "../Users/agent.styles";

const TableWrapper = styled.div`
  background-color: #fff;
  box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 4px 10px 1pxrgba (235, 180, 180, 0.47);
  padding: 20px 30px;
`;
const Details = styled.div`
  margin: 30px 0;
`;
const Span = styled.span`
  color: #fff;
  padding: 2.6px 4.2px;
  font-size: 10.5px;
  border-radius: 5px;
`;

const PCharges = () => {
  const { charges } = useSelector((state) => state.transaction);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(charges?.last_page);
  const [currentItems, setCurrentItems] = useState(charges?.data);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const itemsPerPage = charges?.per_page;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      charges?.data && charges?.data.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(charges?.total / itemsPerPage));
  }, [itemOffset, charges, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % charges?.data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    getCharges(dispatch, currentPage);
  }, [currentPage, dispatch]);

  // if (isProcessing) {
  //   <Loading>
  //     <CircularProgress style={{ color: "blue" }} />
  //   </Loading>;
  // }

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Charges History</H3>
            <DescP>
              Transaction / <DescSpan>Charges History</DescSpan>
            </DescP>
          </Desc>
        </Wrapper>
        <TableWrapper>
          <Details>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                    <TableCell style={{ color: "#8887a9" }}>id</TableCell>
                    <TableCell style={{ color: "#8887a9" }}>GL</TableCell>
                    <TableCell style={{ color: "#8887a9" }}>Type</TableCell>
                    <TableCell style={{ color: "#8887a9" }}>Amount</TableCell>
                    <TableCell style={{ color: "#8887a9" }}>
                      Narration
                    </TableCell>
                    <TableCell style={{ color: "#8887a9" }}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentItems &&
                    currentItems.map((row) => (
                      <TableRow
                        key={row.id}
                        style={{ backgroundColor: "#f3f2f7" }}
                      >
                        <TableCell style={{ color: "#8887a9" }}>
                          {row.id}
                        </TableCell>
                        <TableCell style={{ color: "#8887a9" }}>
                          {row.gl}
                        </TableCell>
                        <TableCell style={{ color: "#8887a9" }}>
                          <Span
                            style={{
                              backgroundColor: `${
                                row.type === "income" ? "#5dd099" : "#f8c955"
                              }`,
                            }}
                          >
                            {row.type}
                          </Span>
                        </TableCell>
                        <TableCell style={{ color: "#8887a9" }}>
                          ₦{formatAmount(row.amount)}
                        </TableCell>
                        <TableCell style={{ color: "#8887a9" }}>
                          {row.narration}
                        </TableCell>
                        <TableCell style={{ color: "#8887a9" }}>
                          {row.date}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <PagWrapper>
              <PageNotification>
                Showing {charges?.from || "0"} to{" "}
                {charges?.to || "0"} of {charges?.total || "0"}{" "}
                entries
              </PageNotification>
              <PaginateContainer
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName="pageNum"
                previousLinkClassName="pageNum"
                nextLinkClassName="pageNum"
                forcePage={currentPage - 1}
              />
            </PagWrapper>
          </Details>
        </TableWrapper>
      </Container>
      <Footer />
    </>
  );
};

export default PCharges;
