import {
  CircularProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import { Desc, DescP, DescSpan, H3 } from "../transaction/transHistory.styles";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { H2, MsgContainer } from "../transaction/pending.styles";
import { Close, DisabledByDefault, NoteAdd, Search } from "@mui/icons-material";

import useInput from "../../Hooks/use-form";
import { clearError, clearMessage } from "../../Redux/pendingTransSlice";
import Server from "../ServiceControls/server";
import { formatAmount } from "../../utills/formatNumber";
import {
  BtnConatiner,
  BtnDisable,
  BtnEnable,
  Container,
  Span,
  Wrapper,
} from "./airtimeControl.styles";
import {
  Btn,
  ButtonWrapper,
  Details,
  Form,
  FormWrapper,
  ModifyBtn,
  P,
  TableWrapper,
} from "../ServiceControls/airtel.styles";
import { userRequest } from "../../requestMethods";
import { toast, ToastContainer } from "react-toastify";
import { getDataConList } from "../../Redux/apiCalls";

const DataControl = () => {
  const { dataConList, isFetching, error, disableMtn, disMessage } =
    useSelector((state) => state.airtimeConverter);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(new Set());

  const { value: enteredNetwork, valueChangeHandler: networkInputChange } =
    useInput("MTN");

  const { value: enteredType, valueChangeHandler: typeInputChange } =
    useInput("CG");

  const { value: enteredServer, valueChangeHandler: serverInputChange } =
    useInput("10");

  const allInputValues = enteredType || enteredServer || enteredNetwork;

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (allInputValues.trim() === "") {
      return getDataConList(
        dispatch,
        enteredNetwork,
        enteredType,
        enteredServer
      );
    }
    getDataConList(dispatch, enteredNetwork, enteredType, enteredServer);
  };

  const handleClose = () => {
    dispatch(clearMessage());
    dispatch(clearError());
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log(dataConList, "");

  const handleButtonClick = async (network, category, id, server) => {
    setLoading((prev) => new Set(prev).add(id));

    try {
      const res = await userRequest.get(
        `/resellerDataConfigModify/${network}/${category}/${id}/${server}`
      );
      console.log(res);

      if (res?.data?.success === 1) {
        toast.success(res?.data?.message);
        getDataConList(dispatch, enteredNetwork, enteredType, enteredServer);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading((prev) => {
        const newSet = new Set(prev);
        newSet.delete(id);
        return newSet;
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Reseller Data Plans</H3>
            <DescP>
              Services / <DescSpan>Reseller Data Plans</DescSpan>
            </DescP>
          </Desc>
          <FormWrapper onSubmit={handleFormSubmission}>
            {error && (
              <MsgContainer>
                <H2>The fields cannot be empty!</H2>
                <Close
                  onClick={handleClose}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}

            {disMessage && (
              <MsgContainer type="success">
                <H2 type="success">{`MTN  ${enteredType} ${disableMtn?.message} (Disabled)`}</H2>
                <Close
                  onClick={handleClose}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}
            <Form>
              <TextField
                select
                label="Select Network"
                variant="outlined"
                onChange={networkInputChange}
                value={enteredNetwork}
              >
                <MenuItem key="CG" value="MTN">
                  MTN
                </MenuItem>
                <MenuItem key="SME" value="AIRTEL">
                  AIRTEL
                </MenuItem>
                <MenuItem key="DG" value="GLO">
                  GLO
                </MenuItem>
                <MenuItem key="SME2" value="9MOBILE">
                  9MOBILE
                </MenuItem>
              </TextField>
              <TextField
                select
                label="Select Type"
                variant="outlined"
                onChange={typeInputChange}
                value={enteredType}
              >
                <MenuItem key="CG" value="CG">
                  CG
                </MenuItem>
                <MenuItem key="SME" value="SME">
                  SME
                </MenuItem>
                <MenuItem key="DG" value="DG">
                  DG
                </MenuItem>
                <MenuItem key="SME2" value="SME2">
                  SME2
                </MenuItem>
                <MenuItem key="COOL" value="COOL">
                  COOL
                </MenuItem>
                <MenuItem key="DATA SHARE" value="DATA SHARE">
                  DATA SHARE
                </MenuItem>
                <MenuItem key="SPECIAL" value="SPECIAL">
                  SPECIAL
                </MenuItem>
                <MenuItem key="AWOOF" value="AWOOF">
                  AWOOF
                </MenuItem>
                <MenuItem key="COUPONS" value="COUPONS">
                  COUPONS
                </MenuItem>
                <MenuItem key="XTRADATA" value="XTRADATA">
                  XTRADATA
                </MenuItem>
              </TextField>

              <TextField
                select
                label="Select Server"
                variant="outlined"
                onChange={serverInputChange}
                value={enteredServer}
              >
                {Server.map((server) => (
                  <MenuItem key={server.id} value={server.value}>
                    {server.value}
                  </MenuItem>
                ))}
              </TextField>
            </Form>
            <ButtonWrapper>
              <Btn type="submit">
                <Search />
                {"Search"}
              </Btn>
              <ModifyBtn
                onClick={() =>
                  handleButtonClick(
                    enteredNetwork,
                    enteredType,
                    1,
                    enteredServer
                  )
                }
              >
                <NoteAdd />
                {"Enable"}
              </ModifyBtn>
              <ModifyBtn
                type="disable"
                onClick={() =>
                  handleButtonClick(
                    enteredNetwork,
                    enteredType,
                    0,
                    enteredServer
                  )
                }
              >
                <DisabledByDefault />
                {"Disable"}
              </ModifyBtn>
            </ButtonWrapper>
          </FormWrapper>
          {dataConList && (
            <TableWrapper>
              <P>Data Plans</P>
              <Details>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Id
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Network
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Product Name
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Provider Price
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Your Price
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Server
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Status{" "}
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Date Modified
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "bold" }}
                        >
                          Enable/Disable
                        </TableCell>
                        <TableCell
                          style={{ color: "#8281cc", fontWeight: "700" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {isFetching ? (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            height: "50vh",
                          }}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : dataConList?.data && dataConList?.data?.length < 1 ? (
                      <TableRow style={{ backgroundColor: "#f3f2f7" }}>
                        <TableCell
                          colSpan={10}
                          style={{ textAlign: "center", color: "#8887a9" }}
                        >
                          No data in the table
                        </TableCell>
                      </TableRow>
                    ) : (
                      dataConList.data &&
                      dataConList.data.map((row) => (
                        <TableBody key={row.id}>
                          <TableRow
                            style={{
                              backgroundColor:
                                row.id % 2 === 0 ? "#ffffff" : "#f3f2f7",
                            }}
                          >
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.id}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.network}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.category}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.name}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              ₦{formatAmount(row.price)}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              ₦{formatAmount(row.amount)}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.server}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              <Span
                                style={{
                                  backgroundColor: `${
                                    row.status === 1 ? "#5dd099" : "#f8c955"
                                  }`,
                                }}
                              >
                                {row.status === 1 ? "Active" : "Inactive"}
                              </Span>
                            </TableCell>

                            <TableCell style={{ color: "#8887a9" }}>
                              {row.updated_at}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              {row.status === 1 ? (
                                <BtnDisable
                                  onClick={() =>
                                    handleButtonClick(
                                      row.network,
                                      row.category,
                                      0,
                                      row.server
                                    )
                                  }
                                >
                                  {loading.has(row.id) ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    "Disable"
                                  )}
                                </BtnDisable>
                              ) : (
                                <BtnEnable
                                  onClick={() =>
                                    handleButtonClick(
                                      row.network,
                                      row.category,
                                      1,
                                      row.server
                                    )
                                  }
                                >
                                  {loading.has(row.id) ? (
                                    <CircularProgress size={24} />
                                  ) : (
                                    "Enable"
                                  )}
                                </BtnEnable>
                              )}
                            </TableCell>
                            <TableCell style={{ color: "#8887a9" }}>
                              <BtnConatiner
                                to={`/reseller/datacontrol/${row.id}`}
                              >
                                Modify
                              </BtnConatiner>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))
                    )}
                  </Table>
                </TableContainer>
              </Details>
              {dataConList?.data && dataConList?.data?.length > 0 && (
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={dataConList.data?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableWrapper>
          )}
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default DataControl;
