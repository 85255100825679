import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/Bar/Navbar";
import Footer from "../../components/footer/Footer";
import {
  delivered,
  getInprogressHistoryData,
  reProcess,
  reversal,
} from "../../Redux/apiCalls";
import {
  Container,
  Desc,
  DescP,
  DescSpan,
  H3,
  Wrapper,
} from "./transHistory.styles";
import {
  BtnConatiner,
  Button,
  H2,
  MsgContainer,
  P,
  Span,
  Title,
  TransList,
} from "./pending.styles";
import { Close } from "@mui/icons-material";
import {
  clearError,
  clearMessage,
  emptyCheckbox,
  toggleCheckbox,
} from "../../Redux/pendingTransSlice";
import PendingTables from "../../components/Pendingtable/PendingTables";

const Inprogress = () => {
  const { checkId, error, message, inprogressTransHistory, isProcessing } =
    useSelector((state) => state.transaction);

  const dispatch = useDispatch();
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(inprogressTransHistory?.to);
  const [currentItems, setCurrentItems] = useState(
    inprogressTransHistory?.data
  );
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 25;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      inprogressTransHistory?.data &&
        inprogressTransHistory?.data.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(inprogressTransHistory?.to / itemsPerPage));
  }, [itemOffset, inprogressTransHistory, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % inprogressTransHistory?.data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    getInprogressHistoryData(dispatch, currentPage);
  }, [dispatch, currentPage]);

  const handleReProcess = () => {
    if (checkId.length === 0) {
      return dispatch(emptyCheckbox(error));
    } else {
      reProcess(dispatch, checkId);
      dispatch(emptyCheckbox());
    }
  };

  const handleChange = (e) => {
    const id = e.target.value;
    dispatch(toggleCheckbox(id));
  };

  const handleRevesal = (e) => {
    if (checkId?.length === 0) {
      return dispatch(emptyCheckbox(error));
    } else {
      reversal(dispatch, checkId);
      dispatch(emptyCheckbox());
    }
  };

  const handleClose = () => {
    dispatch(clearMessage());
    dispatch(clearError());
  };

  const handleDelivered = () => {
    if (checkId.length === 0) {
      return dispatch(emptyCheckbox(error));
    } else {
      delivered(dispatch, checkId);
      dispatch(emptyCheckbox());
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Wrapper>
          <Desc>
            <H3>Inprogress Transaction List</H3>
            <DescP>
              Transaction / <DescSpan>Inprogress Transactions List</DescSpan>
            </DescP>
          </Desc>
          {/* <CardContainer>
            <Card type="totalTransactions" />
            <Card type="transactionToday" />
            <Card type="transactionYesterday" />
            <Card type="transactionAgo" />
          </CardContainer> */}

          <TransList>
            <Title>Inprogress Transaction List</Title>
            <P>
              Click on <Span>Re-process</Span> to reprocess in background.
            </P>
            {error && (
              <MsgContainer>
                <H2>Kindly select some box!</H2>
                <Close
                  onClick={handleClose}
                  style={{ color: "#806e6b", cursor: "pointer" }}
                />
              </MsgContainer>
            )}
            {message && (
              <MsgContainer type="success">
                <H2 type="success">
                  Transactions has been process in background
                </H2>
                <Close
                  style={{ color: "#806e6b", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </MsgContainer>
            )}

            <BtnConatiner>
              <Button type="Re-process Selected" onClick={handleReProcess}>
                Re-process Selected
              </Button>
              <Button type="Mark Delivered Selected" onClick={handleDelivered}>
                Mark Delivered Selected
              </Button>
              <Button
                type="Reverse Transaction Selected"
                onClick={handleRevesal}
              >
                Reverse Transaction Selected
              </Button>
            </BtnConatiner>
            <div style={{ marginTop: "10px" }}>
              <PendingTables
                showerr={error}
                handleChange={handleChange}
                handleRevesal={handleRevesal}
                pendingTrans={inprogressTransHistory}
                pageCount={pageCount}
                currentItems={currentItems}
                handlePageClick={handlePageClick}
                currentPage={currentPage}
                isProcessing={isProcessing}
              />
            </div>
          </TransList>

          {/* TABLES */}
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
};

export default Inprogress;
